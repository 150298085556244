<template>
  <div>
    <navbar
      ref="navbar"
      @getScreenWidths="getScreenWidths"
      @getScrollView="getScrollView"
    ></navbar>

    <div
      class="container"
      :style="screenWidths > 820 ? '' : 'padding-top:40px;'"
    >
      <div class="topShow">
        <img class="topShowImg" src="@/assets/image/solution/top.png" alt="" />
      </div>

      <el-row style="margin: 0; display: flex; flex-wrap: wrap">
        <el-col
          :xs="{ span: 24, offset: 0 }"
          :sm="{ span: 24, offset: 0 }"
          :md="{ span: 24, offset: 0 }"
          :lg="{ span: 20, offset: 2 }"
          :xl="{ span: 16, offset: 4 }"
        >
          <div class="title">
            <div class="title-t">解决方案</div>
            <div class="title-b"></div>
          </div>
          <!-- module_left -->
          <div class="module_left_t_title" ref="solution1">
            数字化校园云平台
          </div>
          <div class="module_left">
            <el-row
              :gutter="32"
              style="margin: 0; display: flex; flex-wrap: wrap"
            >
              <el-col
                :xs="{ span: 24, offset: 0 }"
                :sm="{ span: 24, offset: 0 }"
                :md="{ span: 12, offset: 0 }"
                :lg="{ span: 12, offset: 0 }"
                :xl="{ span: 12, offset: 0 }"
              >
                <div :class="'module_left_i bounce-in-left'">
                  <img src="@/assets/image/solution/szxy.png" alt="" />
                </div>
              </el-col>
              <el-col
                :xs="{ span: 24, offset: 0 }"
                :sm="{ span: 24, offset: 0 }"
                :md="{ span: 12, offset: 0 }"
                :lg="{ span: 12, offset: 0 }"
                :xl="{ span: 12, offset: 0 }"
                style="height: 100%"
              >
                <div :class="'module_left_t bounce-in-right'">
                  <div class="module_left_t_text">
                    <p>
                      构建综合性数字化校园云平台：为学校提供一站式教育教学服务，促进前沿科技与教育教学深度融合，全面提升教学水平和管理水平。
                    </p>
                    <p>
                      提升学校教学教务管理效率，有效减轻老师工作负担：1.
                      事务执行标准化，提升教学管理效率；2.
                      事务执行自动化，减轻老师日常负担。
                    </p>
                    <p>
                      信息化与教学深度融合，助力学校提升教学水平：1.
                      优质资源全面覆盖，满足学校日常需求；2.
                      涵盖课前、课中、课后不同教学环节；3.
                      软硬件结合，互动科技提升教学效率。
                    </p>
                    <p>
                      以评价促进学生、教师、学校更好地发展：1.
                      过程性数据实时采集，确保发展“留痕”；2.
                      多维度指标体系个性化配置，满足多方需求；3.
                      各类档案/报告自动生成，为决策提供依据；
                    </p>
                    <p>
                      架起家校共育桥梁，共同构建师生美好校园生活：1.
                      智慧屏幕系统，打造校园信息视窗；2.
                      家校共育体系，构建学生信息同步视窗，共同促进学生成长。
                    </p>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>

          <!-- module_righ -->
          <div class="module_left_t_title" ref="solution2">智慧乡镇平台</div>
          <div class="module_left">
            <el-row
              :gutter="32"
              style="margin: 0; display: flex; flex-wrap: wrap;align-items: center;"
            >
              <el-col
                :xs="{ span: 24, offset: 0 }"
                :sm="{ span: 24, offset: 0 }"
                :md="{ span: 12, offset: 0 }"
                :lg="{ span: 12, offset: 0 }"
                :xl="{ span: 12, offset: 0 }"
              >
                <div
                  :class="effect2 ? 'module_left_t bounce-in-right' : 'disnone'"
                >
                  <div class="module_left_t_text">
                    <p>
                      “智慧乡镇”是为响应国家“乡村振兴战略”，面向乡镇和农村推出的信息化管理平台，旨为提高基层治理水平、丰富服务手段、便捷百姓生活的综合信息化平台。
                    </p>
                    <p>
                      惠民：提升居家农户安全感、外出务工可远程监控、劳务务工服务促进增收、随时了解村内通知、及时上报百姓困难、惠农便利智享生活。对于农户，侧重服务，达到方便快捷、智慧生活的效果。
                    </p>
                    <p>
                      兴治：党建学习建设保障、乡村安全管理提升、村内通知精准传达、疫情防控高效管理、基层意见收集工具、基层治理工作有抓手。对于村委，侧重乡村管理，达到平安乡村、高效治理的效果。
                    </p>
                    <p>
                      普政：解决综合治理最后一公里的问题、数据监控与业务协同深度结合、为政府决策提供数据支持。全局统筹监管，达到打造乡村数字治理新模式、推进实现乡村振兴的效果。
                    </p>
                  </div>
                </div>
              </el-col>
              <el-col
                :xs="{ span: 24, offset: 0 }"
                :sm="{ span: 24, offset: 0 }"
                :md="{ span: 12, offset: 0 }"
                :lg="{ span: 12, offset: 0 }"
                :xl="{ span: 12, offset: 0 }"
                style="height: 100%"
              >
                <div
                  :class="effect2 ? 'module_left_i bounce-in-left' : 'disnone'"
                >
                  <img src="@/assets/image/solution/zhxz.png" alt="" />
                </div>
              </el-col>
            </el-row>
          </div>

          <!-- module_left -->
          <div class="module_left_t_title" ref="solution3">
            智慧城市网格化平台
          </div>
          <div class="module_left">
            <el-row
              :gutter="32"
              style="margin: 0; display: flex; flex-wrap: wrap"
            >
              <el-col
                :xs="{ span: 24, offset: 0 }"
                :sm="{ span: 24, offset: 0 }"
                :md="{ span: 12, offset: 0 }"
                :lg="{ span: 12, offset: 0 }"
                :xl="{ span: 12, offset: 0 }"
              >
                <div
                  :class="
                    effect3 ? 'module_left_i  bounce-in-right' : 'disnone'
                  "
                >
                  <img src="@/assets/image/solution/zhcs.png" alt="" />
                </div>
              </el-col>
              <el-col
                :xs="{ span: 24, offset: 0 }"
                :sm="{ span: 24, offset: 0 }"
                :md="{ span: 12, offset: 0 }"
                :lg="{ span: 12, offset: 0 }"
                :xl="{ span: 12, offset: 0 }"
                style="height: 100%"
              >
                <div
                  :class="effect3 ? 'module_left_t bounce-in-left' : 'disnone'"
                >
                  <div class="module_left_t_text">
                    <p>
                      一个平台：县统一建设网格化综合管理系统；这同时也意味着全县各部门已建的网格化平台都要逐步纳入到这个统一的大平台中；
                    </p>
                    <p>
                      两种渠道——信息采集渠道来自条和块两个方面：1、块主要是乡镇/街道、村/社区直到网格；2、条主要是整合相关各部门信息，通过双向动态采集机制、保障信息的鲜活性、准确性和全面性；
                    </p>
                    <p>
                      五大要素——信息数据由社会管理五大要素组成：人、地、事、物、组织
                    </p>
                    <p>
                      五级网格：县、各成员单位、乡镇/街道、村/社区五级网格管理；对各类问题做到及时发现、动态掌握、有效解决
                    </p>
                    <p>
                      十二大应用场景：综治网格管理、数字城市管理、两违网格管理、文明创城网格、应急部门网格、公共安全网格、公路长网格、防火安全网格、食品安全网格、国土监察网格环保网格管理、河长制网格管理。
                    </p>
                  </div>
                </div>
              </el-col>
            </el-row>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
import navbar from "../../components/navbar.vue";
export default {
  components: { navbar },
  data() {
    return {
      effect1: true,
      effect2: false,
      effect3: false,
      screenWidths: 0,
    };
  },
  mounted() {
    this.$nextTick(function () {
      window.addEventListener("scroll", this.onScroll);
    });
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
  methods: {
    getScreenWidths(val) {
      this.screenWidths = val;
    },
    // 滚动条
    getScrollView(id) {
      if (id == 51) {
        this.$refs.solution1.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      } else if (id == 52) {
        this.$refs.solution2.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      } else if (id == 53) {
        this.$refs.solution3.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      }
    },

    onScroll() {
      let scrolled =
        document.documentElement.scrollTop || document.body.scrollTop; // 586、1063分别为第二个和第三个锚点对应的距离
      // console.log(scrolled);
      if (scrolled > 400) {
        this.effect2 = true;
      }
      if (scrolled > 500) {
        this.effect3 = true;
      }
      if (this.screenWidths < 820) {
        this.effect2 = true;
        this.effect3 = true;
      }
    },
  },
};
</script>

<style  scoped>
.bounce-in-left {
  -webkit-animation: bounce-in-left 1.1s both;
  animation: bounce-in-left 1.1s both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-1-21 12:47:37
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-in-left
 * ----------------------------------------
 */
@-webkit-keyframes bounce-in-left {
  0% {
    -webkit-transform: translateX(-600px);
    transform: translateX(-600px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateX(-68px);
    transform: translateX(-68px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateX(-28px);
    transform: translateX(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes bounce-in-left {
  0% {
    -webkit-transform: translateX(-600px);
    transform: translateX(-600px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateX(-68px);
    transform: translateX(-68px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateX(-28px);
    transform: translateX(-28px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.bounce-in-right {
  -webkit-animation: bounce-in-right 1.1s both;
  animation: bounce-in-right 1.1s both;
}

/* ----------------------------------------------
 * Generated by Animista on 2022-1-21 12:39:17
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation bounce-in-right
 * ----------------------------------------
 */
@-webkit-keyframes bounce-in-right {
  0% {
    -webkit-transform: translateX(600px);
    transform: translateX(600px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateX(68px);
    transform: translateX(68px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateX(32px);
    transform: translateX(32px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}
@keyframes bounce-in-right {
  0% {
    -webkit-transform: translateX(600px);
    transform: translateX(600px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 0;
  }
  38% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
  55% {
    -webkit-transform: translateX(68px);
    transform: translateX(68px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  72% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  81% {
    -webkit-transform: translateX(32px);
    transform: translateX(32px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  90% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  95% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
}

.disnone {
  display: none;
}

.topShow {
  width: 100%;
  overflow: hidden;
}
.topShowImg {
  width: 100%;
}

.title {
  width: 100%;
  margin-top: 80px;
  margin-bottom: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding-top: 80px;
  margin-top: 0px;
}
.title-t {
  color: #333;
  font-size: 26px;
  margin-bottom: 16px;
  position: relative;
  font-weight: bold;
  background: #fff;
  overflow: hidden;
}
.title-t::before,
.title-t::after {
  content: "";
  position: absolute;
  top: 100px;
  left: 50%;
  width: 180px;
  height: 180px;
  border-radius: 25% 65% 43% 47%;
  transform: translate(-50%, -50%);
  background: rgba(3, 169, 244, 0.85);
  animation: rotate 10s infinite linear;
  z-index: 1;
  mix-blend-mode: lighten;
}
.title-t::after {
  border-radius: 43% 47% 44% 48%;
  animation: rotate 10s infinite 0.5s linear;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0);
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
.title-b {
  width: 60px;
  height: 2px;
  background-color: rgb(44, 134, 237);
}
/* left */
.module_left {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  margin-bottom: 80px;
  align-items: center;
}

.module_left_i {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.module_left_i img {
  width: 100%;
}

.module_left_t {
  width: 100%;
}
.module_left_t_title {
  width: 100%;
  line-height: 80px;
  font-size: 20px;
  color: #333;
  text-align: center;
  font-weight: bold;
  padding-top: 80px;
  margin-top: -80px;
}
.module_left_t_text {
  font-size: 16px;
  color: #666;
}
.module_left_t_text p {
  text-indent: 2em;
  line-height: 40px;
}

@media only screen and (max-width: 800px) {
  .module_left {
    margin-bottom: 0;
  }
}
</style>